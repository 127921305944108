import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    // {
    //     id: 1,
    //     label: 'MENUITEMS.MENU.TEXT',
    //     isTitle: true
    // },
    {
        id: 0,
        icon: 'bx-home-circle',
        label: 'USERS LIST',
        link: '/users',
        parentId: 2
    },{
        id: 0,
        icon: 'bx-home-circle',
        label: 'USERS CREATION',
        link: '/usercreation',
        parentId: 2
    },{
        id: 0,
        icon: 'bx-home-circle',
        label: 'Bulk Email',
        link: '/bulkemailsend',
        parentId: 2
    },{
        id: 0,
        icon: 'bx-home-circle',
        label: 'BANNER POST',
        link: '/bannerpost',
        parentId: 2
    },
    
    
    
    // {
    //     id: 0,
    //     icon: 'bx-home-circle',
    //     label: 'Graphs',
    //     link: '/graphs',
    //     parentId: 2
    // },
    {
        id: 0,
        icon: 'bx-task',
        label: 'AGENTS DATA',
        link: '/approveagency',
        parentId: 2
    },
     {
        id: 0,
        icon: 'bx-task',
        label: 'WELCOME MESSAGE',
        link: '/welcomemessage',
        parentId: 2
    },
    
    {
        id: 0,
        icon: 'bx-task',
        label: 'ADMIN ACCOUNTS',
        link: '/adminaccounts',
        parentId: 2
    },
    {
        id: 0,
        icon: 'bxs-eraser',
        label: 'FRANCHISE DATA',
        link: '/approvefranchise',
        parentId: 2
    },
    {
        id: 0,
        icon: 'bxs-eraser',
        label: 'Svr-Vision',
        link: '/svrvision',
        parentId: 2
    },
    {
        id: 0,
        icon: 'bx-chat',
        label: 'LEAD APPROVALS',
        link: '/approveleads',
        parentId: 2
    },
    {
        id: 0,
        icon: 'bx-chat',
        label: 'NEWLY ADDED HSCODES',
        link: '/addedhscodes',
        parentId: 2
    },
    {
        id: 0,
        icon: 'bx-chat',
        label: 'Comm Slab Creation',
        link: '/slabcreation',
        parentId: 2
    },
    
    // {
    //     id: 0,
    //     icon: 'bx-chat',
    //     label: 'Post Leads',
    //     link: '/adminpostleads',
    //     parentId: 2
    // },
    {
        id: 0,
        icon: 'bx-chat',
        label: 'RFQ APPROVALS',
        link: '/rfqapproval',
        parentId: 2
    },
    {
        id: 0,
        icon: 'bx-chat',
        label: 'SUBSCRIPTION',
        link: '/createsubscription',
        parentId: 2
    },
    
    
    {
        id: 0,
        icon: 'bx-chat',
        label: 'FRQ APPROVALS',
        link: '/frqapproval',
        parentId: 2
    },
    {
        id: 0,
        icon: 'bxs-eraser',
        label: 'ENQUIRY',
        link: '/enquiry',
        parentId: 2
    },
    // {
    //     id: 0,
    //     icon: 'bx-chat',
    //     label: 'POLICY SUGGESTION',
    //     link: '/policysuggestion',
    //     parentId: 2
    // },
    {
        id: 0,
        icon: 'bx-chat',
        label: 'Support',
        link: '/support',
        parentId: 2
    },
    {
        id: 0,
        icon: 'bx-chat',
        label: 'DEMO VIDEOS',
        link: '/demovideos',
        parentId: 2
    },
    // {
    //     id: 1,
    //     icon: 'bx-chat',
    //     label: 'Agent Subscription',
    //     link: '/agentsubscription',
    //     parentId: 2
    // } ,
    {
        id: 1,
        icon: 'bx-chat',
        label: 'UserDetails',
        link: '/userdetails',
        parentId: 2
    },

    {
        id: 1,
        icon: 'bx-chat',
        label: 'Agent Accounts',
        link: '/agentaccounts',
        parentId: 2
    },
    {
        id: 1,
        icon: 'bx-chat',
        label: 'Members Added',
        link: '/agentuserscnt',
        parentId: 2
    },
    {
        id: 1,
        icon: 'bx-chat',
        label: 'Agent Pay',
        link: '/agentpay',
        parentId: 2
    },
    {
        id: 1,
        icon: 'bx-chat',
        label: 'Request Tracking',
        link: '/requesttracking',
        parentId: 2
    },

    
    
  
    // {
    //     id: 1,
    //     icon: 'bx-chat',
    //     label: 'Agent Post Lead',
    //     link: '/agentpostlead',
    //     parentId: 2
    // },
    {
        id: 1,
        icon: 'bx-chat',
        label: 'Signup Users',
        link: '/bulkusers',
        parentId: 2
    },
    
    {
        id: 1,
        icon: 'bx-chat',
        label: 'View Leads',
        link: '/agentviewleads',
        parentId: 2
    },
  
    {
        id: 2,
        icon: 'bx-chat',
        label: 'Users',
        link: '/franchiseusers',
        parentId: 2
    },
    {
        id: 2,
        icon: 'bx-chat',
        label: 'View Leads',
        link: '/franchiseviewleads',
        parentId: 2
    },
    {
        id: 2,
        icon: 'bx-chat',
        label: 'Accounts',
        link: '/franchiseaccounts',
        parentId: 2
    },
    {
        id: 2,
        icon: 'bx-chat',
        label: 'Payout',
        link: '/franchisepayout',
        parentId: 2
    },
    {
        id: 2,
        icon: 'bx-chat',
        label: 'Request Tracking',
        link: '/franchiserequesttracker',
        parentId: 2
    },
    {
        id: 2,
        icon: 'bx-chat',
        label: 'Request NEF',
        link: '/franchiserequestagency',
        parentId: 2
    },
    
    

    

    

    
    
    

    
    // {
    //     id: 2,
    //     label: 'MENUITEMS.TEXT',
    //     icon: 'bx-home-circle',
    //     badge: {
    //         variant: 'info',
    //         text: 'MENUITEMS.BADGE',
    //     },
    //     subItems: [
    //         {
    //             id: 3,
    //             label: 'MENUITEMS.LIST.AGENCTFORM',
    //             link: '/agentform',
    //             parentId: 2
    //         },
    //         {
    //             id: 4,
    //             label: 'MENUITEMS.LIST.FRANCHISEFORM',
    //             link: '/franchiseform',
    //             parentId: 2
    //         },
    //         {
    //             id: 5,
    //             label: 'MENUITEMS.LIST.POSTLEADS',
    //             link: '/postleads',
    //             parentId: 2
    //         },
    //         {
    //             id: 6,
    //             label: 'MENUITEMS.LIST.PREPOSTLEADS',
    //             link: '/prepostleads',
    //             parentId: 2
    //         },
    //         {
    //             id: 5,
    //             label: 'MENUITEMS.LIST.SEARCH',
    //             link: '/search',
    //             parentId: 2
    //         },
    //         {
    //             id: 6,
    //             label: 'MENUITEMS.LIST.VIEWPROFILE',
    //             link: '/viewprofile',
    //             parentId: 2
    //         },
    //     ]
    // },
    // {
    //     id: 7,
    //     isLayout: true
    // },
    // {
    //     id: 8,
    //     label: 'MENUITEMS.APPS.TEXT',
    //     isTitle: true
    // },
    // {
    //     id: 9,
    //     label: 'MENUITEMS.CALENDAR.TEXT',
    //     icon: 'bx-calendar',
    //     link: '/calendar',
    // },
    // {
    //     id: 10,
    //     label: 'MENUITEMS.CHAT.TEXT',
    //     icon: 'bx-chat',
    //     link: '/chat',
        
    // },
    // {
    //     id: 11,
    //     label: 'MENUITEMS.FILEMANAGER.TEXT',
    //     icon: 'bx-file',
    //     link: '/filemanager',
    //     badge: {
    //         variant: 'success',
    //         text: 'MENUITEMS.FILEMANAGER.BADGE',
    //     },
    // },
    // {
    //     id: 12,
    //     label: 'MENUITEMS.ECOMMERCE.TEXT',
    //     icon: 'bx-store',
    //     subItems: [
    //         {
    //             id: 13,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTS',
    //             link: '/ecommerce/products',
    //             parentId: 12
    //         },
    //         {
    //             id: 14,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTDETAIL',
    //             link: '/ecommerce/product-detail/1',
    //             parentId: 12
    //         },
    //         {
    //             id: 15,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.ORDERS',
    //             link: '/ecommerce/orders',
    //             parentId: 12
    //         },
    //         {
    //             id: 16,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.CUSTOMERS',
    //             link: '/ecommerce/customers',
    //             parentId: 12
    //         },
    //         {
    //             id: 17,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.CART',
    //             link: '/ecommerce/cart',
    //             parentId: 12
    //         },
    //         {
    //             id: 18,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.CHECKOUT',
    //             link: '/ecommerce/checkout',
    //             parentId: 12
    //         },
    //         {
    //             id: 19,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.SHOPS',
    //             link: '/ecommerce/shops',
    //             parentId: 12
    //         },
    //         {
    //             id: 20,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.ADDPRODUCT',
    //             link: '/ecommerce/add-product',
    //             parentId: 12
    //         },
    //     ]
    // },
    // {
    //     id: 21,
    //     label: 'MENUITEMS.CRYPTO.TEXT',
    //     icon: 'bx-bitcoin',
    //     subItems: [
    //         {
    //             id: 22,
    //             label: 'MENUITEMS.CRYPTO.LIST.WALLET',
    //             link: '/crypto/wallet',
    //             parentId: 21
    //         },
    //         {
    //             id: 23,
    //             label: 'MENUITEMS.CRYPTO.LIST.BUY/SELL',
    //             link: '/crypto/buy-sell',
    //             parentId: 21
    //         },
    //         {
    //             id: 24,
    //             label: 'MENUITEMS.CRYPTO.LIST.EXCHANGE',
    //             link: '/crypto/exchange',
    //             parentId: 21
    //         },
    //         {
    //             id: 25,
    //             label: 'MENUITEMS.CRYPTO.LIST.LENDING',
    //             link: '/crypto/lending',
    //             parentId: 21
    //         },
    //         {
    //             id: 26,
    //             label: 'MENUITEMS.CRYPTO.LIST.ORDERS',
    //             link: '/crypto/orders',
    //             parentId: 21
    //         },
    //         {
    //             id: 27,
    //             label: 'MENUITEMS.CRYPTO.LIST.KYCAPPLICATION',
    //             link: '/crypto/kyc-application',
    //             parentId: 21
    //         },
    //         {
    //             id: 28,
    //             label: 'MENUITEMS.CRYPTO.LIST.ICOLANDING',
    //             link: '/crypto-ico-landing',
    //             parentId: 21
    //         }
    //     ]
    // },
    // {
    //     id: 29,
    //     label: 'MENUITEMS.EMAIL.TEXT',
    //     icon: 'bx-envelope',
    //     subItems: [
    //         {
    //             id: 30,
    //             label: 'MENUITEMS.EMAIL.LIST.INBOX',
    //             link: '/email/inbox',
    //             parentId: 29
    //         },
    //         {
    //             id: 31,
    //             label: 'MENUITEMS.EMAIL.LIST.READEMAIL',
    //             link: '/email/read/1',
    //             parentId: 29
    //         },
    //         {
    //             id: 32,
    //             label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.TEXT',
    //             badge: {
    //                 variant: 'success',
    //                 text: 'MENUITEMS.EMAIL.LIST.TEMPLATE.BADGE',
    //             },
    //             parentId: 29,
    //             subItems: [
    //                 {
    //                     id:33 ,
    //                     label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.BASIC',
    //                     link: '/email/basic',
    //                     parentId:32 
    //                 },
    //                 {
    //                     id:34 ,
    //                     label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.ALERT',
    //                     link: '/email/alert',
    //                     parentId:32 
    //                 },
    //                 {
    //                     id:35 ,
    //                     label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.BILLING',
    //                     link: '/email/billing',
    //                     parentId:32 
    //                 }
    //             ]
    //         }
    //     ]
    // },
    // {
    //     id: 36,
    //     label: 'MENUITEMS.INVOICES.TEXT',
    //     icon: 'bx-receipt',
    //     subItems: [
    //         {
    //             id: 37,
    //             label: 'MENUITEMS.INVOICES.LIST.INVOICELIST',
    //             link: '/invoices/list',
    //             parentId: 36
    //         },
    //         {
    //             id: 38,
    //             label: 'MENUITEMS.INVOICES.LIST.INVOICEDETAIL',
    //             link: '/invoices/detail',
    //             parentId: 36
    //         },
    //     ]
    // },
    // {
    //     id: 39,
    //     label: 'MENUITEMS.PROJECTS.TEXT',
    //     icon: 'bx-briefcase-alt-2',
    //     subItems: [
    //         {
    //             id: 40,
    //             label: 'MENUITEMS.PROJECTS.LIST.GRID',
    //             link: '/projects/grid',
    //             parentId: 38
    //         },
    //         {
    //             id: 41,
    //             label: 'MENUITEMS.PROJECTS.LIST.PROJECTLIST',
    //             link: '/projects/list',
    //             parentId: 38
    //         },
    //         {
    //             id: 42,
    //             label: 'MENUITEMS.PROJECTS.LIST.OVERVIEW',
    //             link: '/projects/overview',
    //             parentId: 38
    //         },
    //         {
    //             id: 43,
    //             label: 'MENUITEMS.PROJECTS.LIST.CREATE',
    //             link: '/projects/create',
    //             parentId: 38
    //         }
    //     ]
    // },
    // {
    //     id: 44,
    //     label: 'MENUITEMS.TASKS.TEXT',
    //     icon: 'bx-task',
    //     subItems: [
    //         {
    //             id: 45,
    //             label: 'MENUITEMS.TASKS.LIST.TASKLIST',
    //             link: '/tasks/list',
    //             parentId: 44
    //         },
    //         {
    //             id: 46,
    //             label: 'MENUITEMS.TASKS.LIST.KANBAN',
    //             link: '/tasks/kanban',
    //             parentId: 44
    //         },
    //         {
    //             id: 47,
    //             label: 'MENUITEMS.TASKS.LIST.CREATETASK',
    //             link: '/tasks/create',
    //             parentId: 44
    //         }
    //     ]
    // },
    // {
    //     id: 48,
    //     label: 'MENUITEMS.CONTACTS.TEXT',
    //     icon: 'bxs-user-detail',
    //     subItems: [
    //         {
    //             id: 49,
    //             label: 'MENUITEMS.CONTACTS.LIST.USERGRID',
    //             link: '/contacts/grid',
    //             parentId: 48
    //         },
    //         {
    //             id: 50,
    //             label: 'MENUITEMS.CONTACTS.LIST.USERLIST',
    //             link: '/contacts/list',
    //             parentId: 48
    //         },
    //         {
    //             id: 51,
    //             label: 'MENUITEMS.CONTACTS.LIST.PROFILE',
    //             link: '/contacts/profile',
    //             parentId: 48
    //         }
    //     ]
    // },
    // {
    //     id: 52,
    //     label: 'MENUITEMS.BLOG.TEXT',
    //     icon: 'bx-file',
    //     badge: {
    //         variant: 'success',
    //         text: 'MENUITEMS.EMAIL.LIST.TEMPLATE.BADGE',
    //     },
    //     subItems: [
    //         {
    //             id: 53,
    //             label: 'MENUITEMS.BLOG.LIST.BLOGLIST',
    //             link: '/blog/list',
    //             parentId: 52
    //         },
    //         {
    //             id: 54,
    //             label: 'MENUITEMS.BLOG.LIST.BLOGGRID',
    //             link: '/blog/grid',
    //             parentId: 52
    //         },
    //         {
    //             id: 55,
    //             label: 'MENUITEMS.BLOG.LIST.DETAIL',
    //             link: '/blog/detail',
    //             parentId: 52
    //         },
    //     ]
    // },
    // {
    //     id: 56,
    //     label: 'MENUITEMS.PAGES.TEXT',
    //     isTitle: true
    // },
    // {
    //     id: 57,
    //     label: 'MENUITEMS.AUTHENTICATION.TEXT',
    //     icon: 'bx-user-circle',
    //     badge: {
    //         variant: 'success',
    //         text: 'MENUITEMS.AUTHENTICATION.BADGE',
    //     },
    //     subItems: [
    //         {
    //             id: 58,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN',
    //             link: '/account/login',
    //             parentId: 57
    //         },
    //         {
    //             id: 59,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN2',
    //             link: '/account/login-2',
    //             parentId: 57
    //         },
    //         {
    //             id: 60,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER',
    //             link: '/account/signup',
    //             parentId: 57
    //         },
    //         {
    //             id: 61,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER2',
    //             link: '/account/signup-2',
    //             parentId: 57
    //         },
    //         {
    //             id: 62,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD',
    //             link: '/account/reset-password',
    //             parentId: 57
    //         },
    //         {
    //             id: 63,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD2',
    //             link: '/account/recoverpwd-2',
    //             parentId: 57
    //         },
    //         {
    //             id: 64,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN',
    //             link: '/pages/lock-screen-1',
    //             parentId: 57
    //         },
    //         {
    //             id: 65,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN2',
    //             link: '/pages/lock-screen-2',
    //             parentId: 57
    //         },
    //         {
    //             id: 66,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL',
    //             link: '/pages/confirm-mail',
    //             parentId: 57
    //         },
    //         {
    //             id: 67,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL2',
    //             link: '/pages/confirm-mail-2',
    //             parentId: 57
    //         },
    //         {
    //             id: 68,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION',
    //             link: '/pages/email-verification',
    //             parentId: 57
    //         },
    //         {
    //             id: 69,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION2',
    //             link: '/pages/email-verification-2',
    //             parentId: 57
    //         },
    //         {
    //             id: 70,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION',
    //             link: '/pages/two-step-verification',
    //             parentId: 57
    //         },
    //         {
    //             id: 71,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION2',
    //             link: '/pages/two-step-verification-2',
    //             parentId: 57
    //         }
    //     ]
    // },
    // {
    //     id: 72,
    //     label: 'MENUITEMS.UTILITY.TEXT',
    //     icon: 'bx-file',
    //     subItems: [
    //         {
    //             id: 73,
    //             label: 'MENUITEMS.UTILITY.LIST.STARTER',
    //             link: '/pages/starter',
    //             parentId: 72
    //         },
    //         {
    //             id: 74,
    //             label: 'MENUITEMS.UTILITY.LIST.MAINTENANCE',
    //             link: '/pages/maintenance',
    //             parentId: 72
    //         },
    //         {
    //             id: 74,
    //             label: 'Coming Soon',
    //             link: '/pages/coming-soon',
    //             parentId: 72
    //         },
    //         {
    //             id: 75,
    //             label: 'MENUITEMS.UTILITY.LIST.TIMELINE',
    //             link: '/pages/timeline',
    //             parentId: 72
    //         },
    //         {
    //             id: 76,
    //             label: 'MENUITEMS.UTILITY.LIST.FAQS',
    //             link: '/pages/faqs',
    //             parentId: 72
    //         },
    //         {
    //             id: 77,
    //             label: 'MENUITEMS.UTILITY.LIST.PRICING',
    //             link: '/pages/pricing',
    //             parentId: 72
    //         },
    //         {
    //             id: 78,
    //             label: 'MENUITEMS.UTILITY.LIST.ERROR404',
    //             link: '/pages/404',
    //             parentId: 72
    //         },
    //         {
    //             id: 79,
    //             label: 'MENUITEMS.UTILITY.LIST.ERROR500',
    //             link: '/pages/500',
    //             parentId: 72
    //         },
    //     ]
    // },
    // {
    //     id: 80,
    //     label: 'MENUITEMS.COMPONENTS.TEXT',
    //     isTitle: true
    // },
    // {
    //     id: 81,
    //     label: 'MENUITEMS.UIELEMENTS.TEXT',
    //     icon: 'bx-tone',
    //     subItems: [
    //         {
    //             id: 82,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.ALERTS',
    //             link: '/ui/alerts',
    //             parentId: 81
    //         },
    //         {
    //             id: 83,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.BUTTONS',
    //             link: '/ui/buttons',
    //             parentId: 81
    //         },
    //         {
    //             id: 84,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.CARDS',
    //             link: '/ui/cards',
    //             parentId: 81
    //         },
    //         {
    //             id: 85,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.CAROUSEL',
    //             link: '/ui/carousel',
    //             parentId: 81
    //         },
    //         {
    //             id: 86,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.DROPDOWNS',
    //             link: '/ui/dropdowns',
    //             parentId: 81
    //         },
    //         {
    //             id: 87,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.GRID',
    //             link: '/ui/grid',
    //             parentId: 81
    //         },
    //         {
    //             id: 88,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.IMAGES',
    //             link: '/ui/images',
    //             parentId: 81
    //         },
    //         {
    //             id: 88,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.LIGHTBOX',
    //             link: '/ui/lightbox',
    //             parentId: 81
    //         },
    //         {
    //             id: 89,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.MODALS',
    //             link: '/ui/modals',
    //             parentId: 81
    //         },
    //         {
    //             id: 90,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.RANGESLIDER',
    //             link: '/ui/rangeslider',
    //             parentId: 81
    //         },
    //         {
    //             id: 91,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.PROGRESSBAR',
    //             link: '/ui/progressbar',
    //             parentId: 81
    //         },
    //         {
    //             id: 92,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.SWEETALERT',
    //             link: '/ui/sweet-alert',
    //             parentId: 81
    //         },
    //         {
    //             id: 93,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.TABS',
    //             link: '/ui/tabs-accordions',
    //             parentId: 81
    //         },
    //         {
    //             id: 94,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.TYPOGRAPHY',
    //             link: '/ui/typography',
    //             parentId: 81
    //         },
    //         {
    //             id: 95,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.VIDEO',
    //             link: '/ui/video',
    //             parentId: 81
    //         },
    //         {
    //             id: 96,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.GENERAL',
    //             link: '/ui/general',
    //             parentId: 81
    //         },
    //         {
    //             id: 97,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.COLORS',
    //             link: '/ui/colors',
    //             parentId: 81
    //         },
    //         {
    //             id: 98,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.CROPPER',
    //             link: '/ui/image-crop',
    //             parentId: 81
    //         },
    //     ]
    // },
    // {
    //     id: 99,
    //     label: 'MENUITEMS.FORMS.TEXT',
    //     icon: 'bxs-eraser',
    //     badge: {
    //         variant: 'danger',
    //         text: 'MENUITEMS.FORMS.BADGE',
    //     },
    //     subItems: [
    //         {
    //             id: 100,
    //             label: 'MENUITEMS.FORMS.LIST.ELEMENTS',
    //             link: '/form/elements',
    //             parentId: 99
    //         },
    //         {
    //             id: 101,
    //             label: 'MENUITEMS.FORMS.LIST.LAYOUTS',
    //             link: '/form/layouts',
    //             parentId: 99
    //         },
    //         {
    //             id: 102,
    //             label: 'MENUITEMS.FORMS.LIST.VALIDATION',
    //             link: '/form/validation',
    //             parentId: 99
    //         },
    //         {
    //             id: 103,
    //             label: 'MENUITEMS.FORMS.LIST.ADVANCED',
    //             link: '/form/advanced',
    //             parentId: 99
    //         },
    //         {
    //             id: 104,
    //             label: 'MENUITEMS.FORMS.LIST.EDITOR',
    //             link: '/form/editor',
    //             parentId: 99
    //         },
    //         {
    //             id: 105,
    //             label: 'MENUITEMS.FORMS.LIST.FILEUPLOAD',
    //             link: '/form/uploads',
    //             parentId: 99
    //         },
    //         {
    //             id: 106,
    //             label: 'MENUITEMS.FORMS.LIST.REPEATER',
    //             link: '/form/repeater',
    //             parentId: 99
    //         },
    //         {
    //             id: 107,
    //             label: 'MENUITEMS.FORMS.LIST.WIZARD',
    //             link: '/form/wizard',
    //             parentId: 99
    //         },
    //         {
    //             id: 108,
    //             label: 'MENUITEMS.FORMS.LIST.MASK',
    //             link: '/form/mask',
    //             parentId: 99
    //         }
    //     ]
    // },
    // {
    //     id: 109,
    //     icon: 'bx-list-ul',
    //     label: 'MENUITEMS.TABLES.TEXT',
    //     subItems: [
    //         {
    //             id: 110,
    //             label: 'MENUITEMS.TABLES.LIST.BASIC',
    //             link: '/tables/basic',
    //             parentId: 109
    //         },
    //         {
    //             id: 111,
    //             label: 'MENUITEMS.TABLES.LIST.ADVANCED',
    //             link: '/tables/advanced',
    //             parentId: 109
    //         }
    //     ]
    // },
    // {
    //     id: 112,
    //     icon: 'bxs-bar-chart-alt-2',
    //     label: 'MENUITEMS.CHARTS.TEXT',
    //     subItems: [
    //         {
    //             id: 113,
    //             label: 'MENUITEMS.CHARTS.LIST.APEX',
    //             link: '/charts/apex',
    //             parentId: 112
    //         },
    //         {
    //             id: 114,
    //             label: 'MENUITEMS.CHARTS.LIST.CHARTJS',
    //             link: '/charts/chartjs',
    //             parentId: 112
    //         },
    //         {
    //             id: 115,
    //             label: 'MENUITEMS.CHARTS.LIST.CHARTIST',
    //             link: '/charts/chartist',
    //             parentId: 112
    //         },
    //         {
    //             id: 116,
    //             label: 'MENUITEMS.CHARTS.LIST.ECHART',
    //             link: '/charts/echart',
    //             parentId: 112
    //         }
    //     ]
    // },
    // {
    //     id: 117,
    //     label: 'MENUITEMS.ICONS.TEXT',
    //     icon: 'bx-aperture',
    //     subItems: [
    //         {
    //             id: 118,
    //             label: 'MENUITEMS.ICONS.LIST.BOXICONS',
    //             link: '/icons/boxicons',
    //             parentId: 117
    //         },
    //         {
    //             id: 119,
    //             label: 'MENUITEMS.ICONS.LIST.MATERIALDESIGN',
    //             link: '/icons/materialdesign',
    //             parentId: 117
    //         },
    //         {
    //             id: 120,
    //             label: 'MENUITEMS.ICONS.LIST.DRIPICONS',
    //             link: '/icons/dripicons',
    //             parentId: 117
    //         },
    //         {
    //             id: 121,
    //             label: 'MENUITEMS.ICONS.LIST.FONTAWESOME',
    //             link: '/icons/fontawesome',
    //             parentId: 117
    //         },
    //     ]
    // },
    // {
    //     id: 122,
    //     label: 'MENUITEMS.MAPS.TEXT',
    //     icon: 'bx-map',
    //     subItems: [
    //         {
    //             id: 123,
    //             label: 'MENUITEMS.MAPS.LIST.GOOGLEMAP',
    //             link: '/maps/google',
    //             parentId: 122
    //         }
    //     ]
    // },
    // {
    //     id: 124,
    //     label: 'MENUITEMS.MULTILEVEL.TEXT',
    //     icon: 'bx-share-alt',
    //     subItems: [
    //         {
    //             id: 125,
    //             label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.1',
    //             link: '#',
    //             parentId: 124
    //         },
    //         {
    //             id: 126,
    //             label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.2',
    //             parentId: 124,
    //             subItems: [
    //                 {
    //                     id: 127,
    //                     label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.1',
    //                     parentId: 126,
    //                 },
    //                 {
    //                     id: 128,
    //                     label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.2',
    //                     parentId: 126,
    //                 }
    //             ]
    //         },
    //     ]
    // }
];

