
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/auth.models';
var API = "http://newapi.hscodes.world/nodeapp/";

//var API = "https://localhost:8888/nodeapp/";

@Injectable({ providedIn: 'root' })
export class AuthfakeauthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  finaldata: any = [];

  headerOptions: any = null

  _isLoggedIn: boolean = false

  authSub = new Subject<any>();
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    var data = {
      'usr_ph': email,
      'usr_password': password
    }
    return this.http.post<any>(API + '/newuserlogin', data)
      .pipe(map(user => {
        console.group(user);
        var reportdata = [];
        var resdataval = [];


        // login successful if there's a jwt token in the response
        if (user.status == 200) {
          if (user.data.length > 0) {
            reportdata = user.data.sort();
            console.log(reportdata);
            var i = 0;
            var j = -1;
            if (reportdata.length != 0) {
              for (i = 0; i < reportdata.length; i++) {
                if (i == 0 || (reportdata[i].order_no != reportdata[i - 1].order_no && i > 0)) {
                  j++;
                  resdataval[j] = {};
                  resdataval[j].usr_id = reportdata[i].usr_id;
                  resdataval[j].usr_state = reportdata[i].usr_state;
                  resdataval[j].usr_ph = reportdata[i].usr_ph;
                  resdataval[j].usr_password = reportdata[i].usr_password;
                  resdataval[j].usr_nm = reportdata[i].usr_nm;
                  resdataval[j].usr_email = reportdata[i].usr_email;
                  resdataval[j].usr_district = reportdata[i].usr_district;
                  resdataval[j].usr_country = reportdata[i].usr_country;
                  resdataval[j].usr_continent = reportdata[i].usr_continent;
                  resdataval[j].usr_address = reportdata[i].usr_address;
                  resdataval[j].parentstart = reportdata[i].parentstart;
                  resdataval[j].parentend = reportdata[i].parentend;
                  resdataval[j].premium_payment_indicator = reportdata[i].premium_payment_indicator;
                  resdataval[j].membership_type_indicator = reportdata[i].membership_type_indicator;




                  //   
                  resdataval[j].reportdata = [];

                  resdataval[j].reportdata.push({
                    "purchase_chapter_id": reportdata[i].purchase_chapter_id,
                    "memberstart": reportdata[i].memberstart,
                    "memberend": reportdata[i].memberend,
                    "chapterend": reportdata[i].chapterend,
                    "chapterstart": reportdata[i].chapterstart,
                    "chapter_nm": reportdata[i].chapter_nm,
                    'chapter_id': reportdata[i].chapter_id,
                    'membership_id': reportdata[i].membership_id
                  });

                } else if (reportdata[i].usr_id === reportdata[i - 1].usr_id) {
                  resdataval[j].reportdata.push({
                    "purchase_chapter_id": reportdata[i].purchase_chapter_id,
                    "memberstart": reportdata[i].memberstart,
                    "memberend": reportdata[i].memberend,
                    "chapterend": reportdata[i].chapterend,
                    "chapterstart": reportdata[i].chapterstart,
                    "chapter_nm": reportdata[i].chapter_nm,
                    'chapter_id': reportdata[i].chapter_id,
                    'membership_id': reportdata[i].membership_id
                  });
                }
              }
            }
            this.finaldata = resdataval;
            console.log(this.finaldata);


          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(this.finaldata[0]));
          localStorage.setItem('country_id', this.finaldata[0].usr_country);
          localStorage.setItem('continent_id', this.finaldata[0].continent_id);
          localStorage.setItem('role', this.finaldata[0].role);
          localStorage.setItem('usr_id', this.finaldata[0].usr_id);
          localStorage.setItem('premium_payment_indicator', this.finaldata[0].premium_payment_indicator);
          localStorage.setItem('membership_type_indicator', this.finaldata[0].membership_type_indicator);
          this.currentUserSubject.next(user);
        } else {
          return;
        }
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    // localStorage.removeItem('currentUser');
    // this.currentUserSubject.next(null);
  }


  loginAuth(userObj: any) {
    if (userObj.authcode) {
      console.log('Appending headers');
      this.headerOptions = new HttpHeaders({
        'x-tfa': userObj.authcode
      });
    }
    return this.http.post("http://newapi.hscodes.world/nodeapp/login", { uname: userObj.uname, upass: userObj.upass }, { observe: 'response', headers: this.headerOptions });
    //return this.http.post("https://localhost:8888/nodeapp/login", { uname: userObj.uname, upass: userObj.upass }, { observe: 'response', headers: this.headerOptions });
  }

  setupAuth() {
    var bad = JSON.parse(localStorage.getItem('user_data'))
    console.log(bad['u_id']);
    return this.http.post("http://newapi.hscodes.world/nodeapp/tfa/setups", { u_id: bad['u_id'] }, { observe: 'response' })
  }

  registerUser(userObj: any) {
    return this.http.post("http://newapi.hscodes.world/nodeapp/register", { uname: userObj.uname, upass: userObj.upass }, { observe: "response" });
  }

  updateAuthStatus(value: boolean) {
    this._isLoggedIn = value
    this.authSub.next(this._isLoggedIn);
    localStorage.setItem('isLoggedIn', value ? "true" : "false");
  }

  getAuthStatus() {
    this._isLoggedIn = localStorage.getItem('isLoggedIn') == "true" ? true : false;
    return this._isLoggedIn
  }

  logoutUser() {
    this.deleteAuth();
    this._isLoggedIn = false;
    this.authSub.next(this._isLoggedIn);
    localStorage.setItem('isLoggedIn', "false");
  }

  getAuth() {
    var bad = JSON.parse(localStorage.getItem('user_data'))
    console.log(bad['u_id']);
    return this.http.post("http://newapi.hscodes.world/nodeapp/tfa/setup", { u_id: bad['u_id'] }, { observe: 'response' });
  }

  deleteAuth() {
    var bad = JSON.parse(localStorage.getItem('user_data'))
    console.log(bad['u_id']);
    return this.http.post("http://newapi.hscodes.world/nodeapp/tfa/delete", { u_id: bad['u_id'] }, { observe: 'response' });
  }

  verifyAuth(token: any) {
    var bad = JSON.parse(localStorage.getItem('user_data'))
    console.log(bad['u_id']);
    return this.http.post("http://newapi.hscodes.world/nodeapp/tfa/verify", { token, u_id: bad['u_id'] }, { observe: 'response' });
  }

  isAdminIn() {
    const admin = localStorage.getItem('role');
    return (admin === '0') ? true : false;
  }

  isAgentIn() {
    const agent = localStorage.getItem('role');
    return (agent === '1') ? true : false;
  }

  isFranchiseIn() {
    const agent = localStorage.getItem('role');
    return (agent === '2') ? true : false;
  }

  isSalesIn() {
    const agent = localStorage.getItem('role');
    return (agent === '3') ? true : false;
  }
}
